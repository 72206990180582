import * as APIUrls from './APIUrls'

export const isDevEnv = () => {
    if(process.env.NODE_ENV === 'development')
        return true;
    else return false;
}

export const track = (featureUsage) => {
    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(featureUsage)
    };

    fetch(APIUrls.getUsageTrackAPIURL(), requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}