import React from 'react';
import Nav from 'react-bootstrap/Nav';

import '../../App.css';
import * as BrowserUrls from '../../common/BrowserUrls'

const sidebarItemContainer = {
    marginTop: '2rem',
};

export function LeftNavbar(props){
    let allMailsUrl = BrowserUrls.getStatsUrl(props.accountId);
    let openedMailsUrl = BrowserUrls.getStatsUrl(props.accountId, 'opened');
    let unOpenedMailsUrl = BrowserUrls.getStatsUrl(props.accountId, 'unopened');
    let accountPageUrl = BrowserUrls.getAccountPageUrl(props.accountId);

    return(
        <div className="Sidebar-wide">
            <Nav variant="pills" style={sidebarItemContainer} activeKey={props.selectedNavMenuItem} className="flex-column" onSelect={selectedKey => props.onNavMenuItemChange(`${selectedKey}`)}>
                <Nav.Item><Nav.Link eventKey="all" href={allMailsUrl}><i className="fa fa-bolt mr-4" aria-hidden="true"></i>All Activities</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="opened" href={openedMailsUrl}><i className="fa fa-envelope-open-o mr-3" style={{fontSize: '.85rem'}} aria-hidden="true"></i>Opened Emails</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="unopened" href={unOpenedMailsUrl}><i className="fa fa-paper-plane mr-3" style={{fontSize: '.85rem'}}  aria-hidden="true"></i>Unopened Emails</Nav.Link></Nav.Item>
                <hr/>
                <Nav.Item style={{marginTop: '2rem'}}><Nav.Link eventKey="account" href={accountPageUrl}><i className="fa fa-user-o mr-3" style={{fontSize: '.85rem'}}  aria-hidden="true"></i>My Account</Nav.Link></Nav.Item>
            </Nav>
        </div>
    )
}