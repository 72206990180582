import React, { useState, useEffect }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import '../App.css';
import { ActivityCard } from './ActivityCard';
import * as APIUrls from '../common/APIUrls';
import * as AppConsts from '../common/AppConsts';
import {Loader} from './common/Loader';
import disabledLogo from '../assets/emt128-blank.png';
import * as SimpleAccount from '../common/SimpleAccount';
import * as Utils from '../common/Utils';

export function Dashboard(props){
    let [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false);
    let [threadStats, setThreadStats] = useState([]);
    let [noDataFound, setNoDataFound] = useState(false);
    let [showLoadMore, setShowLoadMore] = useState(true);
    let [allAvailableAccounts, setAllAvailableAccounts] = useState([]);

    //To start with, we will fetch stats for 0-30 days
    let [startDateToFetchStatNumber, setStartDateToFetchStatNumber] = useState(0);
    let accountId = props.accountId;
    let showThreadType = 'all';
    if(props.showThreadType === 'opened' || props.showThreadType === 'unopened') showThreadType = props.showThreadType;

    function handleLoadMoreCards(){
        setStartDateToFetchStatNumber(startDateToFetchStatNumber + AppConsts.FETCH_INTERVAL_DAYS);
    }

    function handleSignOut(){
        SimpleAccount.signOut();
    }

    useEffect(() => {
		setIsLoaded(false);
        fetch(APIUrls.getThreadStatsUrl(accountId, showThreadType, startDateToFetchStatNumber, startDateToFetchStatNumber + AppConsts.FETCH_INTERVAL_DAYS))
        .then((res) => {
            if(res.ok){
                return res.json();
            }
            throw res;
        })
        .then(
            (threadStatData) => {
                setIsLoaded(true);
                let newThreadStats = threadStatData.threadStats;
                let totalEmailsSentBeforeStartTime = threadStatData.totalEmailsSentBeforeStartTime;
                setAllAvailableAccounts(threadStatData.allAvailableAccounts);
                if(newThreadStats && newThreadStats.length > 0) {
                    setThreadStats(threadStats.concat(newThreadStats));
                } else { //Handle cases where no threadStat found for this call
                    //1. If it's the first call (not "Load More") AND No more emails
                    //we need to show "NoDataFoundForUserDiv" Component
                    if(startDateToFetchStatNumber === 0 && totalEmailsSentBeforeStartTime === 0){
                        setNoDataFound(true);
                    }
                    //2. If it's not first call ignore for now. But we should show "No data found for this call"

                    //3. Whether to show "Load More..."
                    if(totalEmailsSentBeforeStartTime === 0){
                        setShowLoadMore(false);
                    }
                }
                Utils.track({
                    accountId: props.accountId,
                    eventName: AppConsts.eventNames.DASHBOARD_ACCESS,
                    attributesJson: JSON.stringify({
                        showThreadType: showThreadType,
                        startPageNum: startDateToFetchStatNumber
                    })
                })
            },
            (error) => {
				setIsLoaded(true);
				setError(error);
            }
        )
	}, [startDateToFetchStatNumber]);

    return(
        <div className="main-container-dashboard">
            <Container>
                <Row>
                    <Col style={{marginBottom: '2rem'}}>Showing Activities for: <span style={{fontWeight: 500, color: '#0052cc'}}>{props.email}</span></Col>
                </Row>
                {!error && 
                    <>
                        {allAvailableAccounts.length > 1 &&
                            <Row>
                                <Col xs={9} style={{marginBottom: '.5rem'}}>
                                    <Alert variant="warning">
                                        To view activities for other Gmail accounts, <Alert.Link onClick={handleSignOut}>Switch Account &nbsp;<i className="fa fa-long-arrow-right" style={{fontSize: '.8rem'}} ></i></Alert.Link>
                                    </Alert>
                                </Col>
                                <Col xs={3}></Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={12} md={9}><ActivityCardContainer isLoaded={isLoaded} threadStats={threadStats} showLoadMore={showLoadMore} onLoadMoreCards={handleLoadMoreCards}/></Col>
                            <Col md={3}></Col>
                        </Row>
                    </>
				}
                {error &&
                    <ErrorDiv show={true}/>
                }
                <NoDataFoundForUserDiv show={noDataFound}/>
                
            </Container>
        </div>
    )
}

function ActivityCardContainer(props){
    if(!props.threadStats) return null;
    const threadCards = props.threadStats.map((threadStat) =>
        <div key={threadStat.gmailThreadId}>
            <ActivityCard threadStat={threadStat}/>
        </div>
    );

    return(
        <>
            {threadCards}
            <Loader show={!props.isLoaded} />
            <LoadMoreCardsDiv show={props.isLoaded && props.showLoadMore} onLoadMoreCards={props.onLoadMoreCards}/>
            <div style={{marginBottom: '3rem'}}></div>
        </>
    )
}

function LoadMoreCardsDiv(props){
    if(!props.show) return null;
    return (
        <div>
            <Button data-tip="hello" variant="link" onClick={props.onLoadMoreCards}>Load More...</Button>
        </div>
    )
}

function NoDataFoundForUserDiv(props){
    if(!props.show) return null;
    return (
        <Container style={{marginTop: '5rem'}}>
            <Row>
                <Col md="2"></Col>
                <Col md="5" style={{textAlign: 'center'}}>
                    <img src={disabledLogo} width="60px"/><br></br><br></br>
                    <span className="h5">Nothing Tracked Yet!</span>
                    <p>Your tracked emails will appear here. Send an email to start tracking.</p>
                </Col>
                <Col md="5"></Col>
            </Row>
        </Container>
    )
}

function ErrorDiv(props){
    if(!props.show) return null;
    return (
        <Container style={{marginTop: '5rem'}}>
            <Row>
                <Col md="2"></Col>
                <Col md="5" style={{textAlign: 'center'}}>
                    <img src={disabledLogo} width="40px"/><br></br><br></br>
                    <span className="h5">Something is broken :(</span>
                    <p>Our engineers have been notified and will fix this soon. Sorry for the trouble.</p>
                </Col>
                <Col md="5"></Col>
            </Row>
        </Container>
    )
}